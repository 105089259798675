import React from 'react'
import PropTypes from 'prop-types'
// import { graphql } from 'gatsby';
// import classNames from 'classnames';
import Layout from 'components/layout'
import Head from 'components/head'
// import Box from 'components/box';
// import PageBlocks from 'components/pageBlocks';
import 'style/blog.scss'

const BlogTemplate = ({ pageContext }) => {
  const fields = pageContext.fields

  console.log({ fields })

  return (
    <Layout locale="nl">
      <Head
        locale={pageContext.locale}
        pageTitle={fields.title}
        pageDescription={fields.fields['Meta description']}
        imageUrl={
          'https://cms.burobork.nl/images/' +
          fields.fields['Feature afbeelding'][0].reference
        }
      />
      <script type="application/ld+json">
        {JSON.stringify([
          {
            '@context': 'http://schema.org',
            '@type': 'Article',
            url: 'https://bukazu.com/blog/' + fields.url,
            content: fields.title,
            image:
              'https://cms.burobork.nl/images/' +
              fields.fields['Feature afbeelding'][0].reference,
          },
        ])}
      </script>
      <div className="blog-container main_article">
        <div className="content_wrap">
          <div className="content">
            <article
              className="article"
              itemScope="itemscope"
              itemType="http://schema.org/Article"
              itemProp="articleBody"
              itemID={'https://bukazu.com/blog/' + fields.url}
            >
              <div
                className="article-image"
                style={{
                  backgroundImage: `url(//cdn.burobork.nl/${fields.fields['Feature afbeelding'][0].reference})`,
                }}
              ></div>
              <div className="article-content">
                <div className="article_header">
                  <div className="article_header">
                    <h1>{fields.title}</h1>
                  </div>
                  <div className="article_meta">
                    <div>{fields.fields.Datum},&nbsp;</div>
                    <div>{fields.fields.Auteur.title}</div>
                    <div>
                      {fields.fields.Categorie.map(cat => (
                        <span key={cat.id}>{cat.title}, </span>
                      ))}
                    </div>
                  </div>

                  <div
                    className="article-content-text"
                    dangerouslySetInnerHTML={{ __html: fields.fields.Text.nl }}
                  />
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

BlogTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default BlogTemplate
